<template>
  <CRow>
    <CCol col="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <CCol sm="6" class="align-self-center">
              <strong>{{ $lang.user_deleted.title }}</strong>
            </CCol>
            <CCol sm="6" class="align-self-center">
              <div class="text-sm-right mt-3 mt-sm-0">
                <CButtonGroup class="file-export">
                  <CButton color="secondary" disabled>
                    {{ $lang.buttons.export.title }}
                  </CButton>
                  <CButton color="danger" v-c-tooltip="$lang.buttons.export.pdf"
                           v-on:click="exports(self,'pdf',module,'DeletedStaffs')">
                    <i class="fas fa-file-pdf"></i>
                  </CButton>
                  <VueJsonToCsv class="btn btn-success" v-c-tooltip="$lang.buttons.export.excel" :json-data="json_data"
                                :labels="json_label" :csv-title="json_title">
                    <i class="fas fa-file-excel"></i>
                  </VueJsonToCsv>
                </CButtonGroup>
              </div>
            </CCol>
          </div>
        </CCardHeader>
        <CCardBody>
          <CAlert v-show="alertMessage" :color="messageColor" closeButton :show.sync="dismissCountDown" fade>
            {{ alertMessage }}
          </CAlert>
          <v-server-table :columns="columns" url="/staff/list-deleted" :options="options" ref="myTable"
                          @loaded="exports(self,'Excel',module,'DeletedStaffs')">
            <template #status="data">
              <CBadge :color="statusBudget(data.row.status)">{{ data.row.status }}</CBadge>
            </template>
            <template #company="data">
              <Avatar :image=data.row.avatar :defaultImage=data.row.avatar :content=data.row.company :length="20"/>
            </template>
            <template #name="data">
              <span :title="data.row.name">{{ trimfunction(data.row.name, 20) }}</span>
            </template>
            <template #title="data">
              <span :title="data.row.title">{{ trimfunction(data.row.title, 20) }}</span>
            </template>
            <template #actions="data">
              <CButton color="primary" v-c-tooltip="$lang.buttons.general.crud.view"
                       v-on:click="viewTableRow(data.row.companyId)">
                <i class="fas fa-eye"></i>
              </CButton>
            </template>
          </v-server-table>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import Vue from "vue";
import VueForm from "vue-form";
import {staff} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import {ServerTable} from 'vue-tables-2';
import VueJsonToCsv from "vue-json-to-csv";
import VueSweetalert2 from 'vue-sweetalert2';
import Avatar from "/src/components/Avatar";

Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false)
Vue.use(VueForm, options);
export default {
  name: 'Users',
  mixins: [Mixin],
  components: {
    VueJsonToCsv,
    Avatar
  },
  data() {
    return {
      dismissCountDown: 0,
      alertMessage: '',
      messageColor: 'success',
      self: this,
      json_data: [],
      json_label: {},
      json_title: "",
      columns: ['company', 'country', 'status', 'actions'],
      data: [],
      options: {
        headings: {
          company: this.$lang.user_deleted.table.company,
          country: this.$lang.user_deleted.table.country,
          status: this.$lang.user_deleted.table.status,
          actions: this.$lang.common.general.actions
        },
        editableColumns: ['company', 'country', 'status'],
        sortable: ['company', 'country', 'status'],
        filterable: ['company', 'country', 'status']
      },
      module: staff,
    }
  },
  created() {
    let self = this;
    if (localStorage.getItem('notificationMessage') !== "") {
      self.dismissCountDown = 10;
      self.alertMessage = localStorage.getItem('notificationMessage');
      self.messageColor = localStorage.getItem('notificationType');
      localStorage.setItem('notificationMessage', '');
      localStorage.setItem('notificationType', '');
    }
  },
  mounted() {
    let self = this;
    self.dismissCountDown = 0;
    // self.loadData();
  },
  methods: {
    pageChange(val) {
      this.$router.push({query: {page: val}})
    },
    loadData() {
      let self = this;
      axios.get(this.listUrlApi(this.module), self.model)
          .then(function (response) {
            if (response.status === 200) {
              self.data = response.data.data;
            }
          });
    },
    viewTableRow(item) {
      this.$router.push({path: `/deleted-users/details/${(item)}`})
    },
  }
}
</script>
